"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultSettings = void 0;
exports.defaultSettings = {
    version: 0,
    site_id: "",
    property_id: "",
    analytics: {
        bigcrunch: true,
        prebid_analytics: false,
        bigcrunch_page_meta: true,
        url_path_groupings: [],
        page_meta_overrides: {}
    },
    gam_network_code: 0,
    gam_advertiser_ids: {},
    gam_order_ids: {
        "Google Ad Exchange": {},
        "Header Bidder": {},
        "Ad Server": {},
        "Amazon": {}
    },
    double_verify: {
        enabled: false,
        ctx: "",
        cmp: ""
    },
    site_name: "",
    slots: {},
    floor: {
        default: 0,
        dynamic: false,
        enabled: false
    },
    adserver: "googletag",
    bidders: {},
    user_syncs: {},
    consent_country_code: "",
    consent_region_code: "",
    cmp: {},
    schain: {
        complete: 1,
        nodes: [],
        ver: "1.0"
    },
    coppa: false,
    test: {
        enabled: false,
        ids: false,
        ads: false,
        bidders: [],
    },
    prebid: {
        enabled: false,
        config: {
            debug: false,
            bidderTimeout: 2000,
            enableSendAllBids: false,
            useBidCache: false,
            deviceAccess: false,
            timeoutBuffer: 400,
            disableAjaxTimeout: false,
            maxNestedIframes: 10,
            priceGranularity: {},
            floors: {},
            mediaTypePriceGranularity: {},
            bidderSequence: "random",
            auctionOptions: {},
            userSync: {},
            targetingControls: {},
            consentManagement: {},
            enableTIDs: true,
            schain: {},
        },
        adUnitBidders: {}
    },
    amazon_aps: {
        enabled: false,
        pubID: "",
        adServer: "googletag",
        timeout: 1000
    },
    "5x5": {
        cookie_sync: false,
        hem_lookup: false,
        pubId: "",
    },
    extra_js: {
        enabled: false
    },
    extra_css: {
        enabled: false
    },
    disable_ads: false
};
