"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalFloors = exports.categorizeCountryWithMap = exports.categorizeBrowser = exports.categorizeDeviceType = exports.Floors = void 0;
var __1 = require("../");
var Session_1 = require("../classes/Session");
var utils_1 = require("../utils/utils");
var envVars_1 = require("../envVars");
var ua_parser_js_1 = require("ua-parser-js");
var Page_1 = require("./Page");
var Floors = /** @class */ (function () {
    function Floors() {
        this.dynamicFloors = {
            "slots": {},
            "refreshLR": [],
            "sessionLR": [] // Session Linear Regression
        };
    }
    Floors.prototype.getDynamicFloors = function () {
        var _this = this;
        var ua = new ua_parser_js_1.UAParser((0, utils_1.getUserAgent)());
        var device = categorizeDeviceType(ua.getDevice().type || 'desktop');
        var browser = categorizeBrowser(ua.getBrowser().name);
        var country = categorizeCountryWithMap(__1.globalSettings.consent_country_code);
        var property_id = __1.globalSettings.property_id;
        // fetch flooring data from server
        fetch("https://".concat(envVars_1.endpoint_prefix, "ship.bigcrunch.com/flooring/").concat(property_id, "-").concat(device, "-").concat(browser, "-").concat(country, ".json?rev=").concat(__1.VERSION)).then(function (response) {
            if (response.ok) {
                return response.json();
            }
        }).then(function (data) {
            if (data && data.slots) {
                _this.dynamicFloors = data;
                // loop through globalPage slots and set the floors
                for (var slotName in Page_1.globalPage.slots) {
                    var slot = Page_1.globalPage.slots[slotName];
                    if (data.slots[slot.name]) {
                        // set the floor for the slot
                        slot.floor = _this.calculateSlotFloor(slot);
                    }
                }
                // fire custom event
                var event_1 = new CustomEvent('BcDynamicFloors', { detail: _this.dynamicFloors });
                document.dispatchEvent(event_1);
            }
        });
    };
    Floors.prototype.calculateSlotFloor = function (slot) {
        // Check if the slot name exists in the dynamic floors
        if (__1.globalSettings.floor.dynamic && this.dynamicFloors.slots[slot.name]) {
            var slotFloor = this.dynamicFloors.slots[slot.name];
            var sessionLR = this.dynamicFloors.sessionLR; // Session Linear Regression
            var refreshLR = this.dynamicFloors.refreshLR; // Refresh Linear Regression
            var sessionLRValue = (sessionLR[0] * Session_1.globalSession.data.depth) + slotFloor;
            var refreshLRValue = (refreshLR[0] * slot.refresh.count) + sessionLRValue;
            var adjustedFloor = Math.round(Math.max(refreshLRValue, slot.default_floor) * 100) / 100;
            return adjustedFloor;
        }
        return slot.floor; // Default floor value if slot name not found
    };
    return Floors;
}());
exports.Floors = Floors;
/**
 *
 * @param device - The device type to categorize.
 * @returns {string} - The categorized device type: 'desktop', 'mobile', 'tablet', or 'other'.
 */
function categorizeDeviceType(device) {
    //  WHEN device IN ('desktop', 'mobile', 'tablet') THEN device ELSE 'other'
    var deviceType = device.toLowerCase();
    if (deviceType === 'desktop' || deviceType === 'mobile' || deviceType === 'tablet') {
        return deviceType;
    }
    else {
        return 'other';
    }
}
exports.categorizeDeviceType = categorizeDeviceType;
/**
 * Categorizes a list of browsers into top 5 categories and "Other".
 * @param {string} browser - An array of browser names.
 * @returns {Object} An object with categories as keys and arrays of browser names as values.
 */
function categorizeBrowser(browser) {
    // Initialize the default category
    var browser_category = 'Other';
    // Define the top 5 categories with their corresponding regex patterns
    var categories = {
        'Chrome': /chrome|chromium|brave|vivaldi|whale|yandex|coc coc|baidubrowser|qqbrowser|360 browser|avast secure browser|ucbrowser/i,
        'Firefox': /firefox|waterfox|pale moon|qupzilla|seamonkey|falkon|fennec|firebird|iceweasel|swiftfox|iron/i,
        'Safari': /safari|mobile safari/i,
        'Edge': /\bedge\b|edg/i,
        'IE': /\bie\b|iemobile/i
    };
    // Clean the browser name by removing quotes and trimming whitespace
    var name = browser.replace(/["']/g, '').trim();
    // Skip empty strings
    if (!name)
        return browser_category;
    // Iterate through each category to find a match
    for (var _i = 0, _a = Object.entries(categories); _i < _a.length; _i++) {
        var _b = _a[_i], category = _b[0], regex = _b[1];
        if (regex.test(name)) {
            browser_category = category;
            break; // Stop checking other categories once matched
        }
    }
    return browser_category;
}
exports.categorizeBrowser = categorizeBrowser;
/**
 * Categorizes a given 2-letter country code into a predefined region using a mapping object.
 *
 * @param {string} countryCode - The 2-letter ISO country code to categorize.
 * @returns {string} - The region the country belongs to: 'US', 'UK', 'EU', 'APAC', or 'Other'.
 * @throws {TypeError} - If the input is not a string.
 * @throws {Error} - If the input string is not exactly 2 characters long.
 */
function categorizeCountryWithMap(countryCode) {
    var trimmedCode = countryCode.trim().toUpperCase();
    // Mapping object: country_code => region
    var countryRegionMap = {
        // US
        'US': 'US',
        // UK
        'GB': 'UK',
        // EU Countries
        'AT': 'EU', 'BE': 'EU', 'BG': 'EU', 'HR': 'EU', 'CY': 'EU', 'CZ': 'EU',
        'DK': 'EU', 'EE': 'EU', 'FI': 'EU', 'FR': 'EU', 'DE': 'EU', 'GR': 'EU',
        'HU': 'EU', 'IE': 'EU', 'IT': 'EU', 'LV': 'EU', 'LT': 'EU', 'LU': 'EU',
        'MT': 'EU', 'NL': 'EU', 'PL': 'EU', 'PT': 'EU', 'RO': 'EU', 'SK': 'EU',
        'SI': 'EU', 'ES': 'EU', 'SE': 'EU',
        // APAC Countries
        'AU': 'APAC', 'CN': 'APAC', 'JP': 'APAC', 'IN': 'APAC', 'KR': 'APAC',
        'SG': 'APAC', 'NZ': 'APAC', 'MY': 'APAC', 'ID': 'APAC', 'PH': 'APAC',
        'TH': 'APAC', 'VN': 'APAC', 'TW': 'APAC', 'HK': 'APAC',
    };
    return countryRegionMap[trimmedCode] || 'Other';
}
exports.categorizeCountryWithMap = categorizeCountryWithMap;
exports.globalFloors = new Floors();
