"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalIdentityAdapeterHandler = void 0;
var _1 = require("../../.");
var User_1 = require("../User");
var ConsentHandler_1 = require("../consent/ConsentHandler");
var FiveByFive_1 = require("../FiveByFive");
var activities_1 = require("../../modules/consent/activities");
var log_1 = require("../../utils/log");
var utils_1 = require("../../utils/utils");
var IdentityAdapeterHandler = /** @class */ (function () {
    function IdentityAdapeterHandler() {
        this._pending = [];
        this._timeout = 2000;
        this._timeout_handle = null;
        this.started = false;
        this.ready = false;
        this.callback = function () { };
        this.started = true;
        this.ready = false;
        document.addEventListener('BcUserHasedEmail', function (event) {
            var _a, _b, _c;
            if ((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.SHA256) {
                this.checkUid2((_b = event === null || event === void 0 ? void 0 : event.detail) === null || _b === void 0 ? void 0 : _b.SHA256);
                this.checkId5((_c = event === null || event === void 0 ? void 0 : event.detail) === null || _c === void 0 ? void 0 : _c.SHA256);
            }
        }.bind(this));
    }
    IdentityAdapeterHandler.prototype.runAdapters = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                // 5x5 cookie sync
                if (_1.globalSettings['5x5'].cookie_sync && _1.globalSettings['5x5'].pubId) {
                    FiveByFive_1.globalFiveByFive.insertCookieSyncTag(_1.globalSettings['5x5'].pubId);
                }
                // 5x5 hem lookup
                if (_1.globalSettings['5x5'].hem_lookup) {
                    this._pending.push('5x5');
                    // set a timeout
                    if (!this._timeout_handle) {
                        this._timeout_handle = setTimeout(function () {
                            (0, log_1.logInfo)('Identity adapter timeout');
                            clearTimeout(_this._timeout_handle);
                            _this._timeout_handle = null;
                            _this.ready = true;
                            _this.checkIfReady();
                        }, this._timeout);
                    }
                    (0, utils_1.timeoutPromise)(FiveByFive_1.globalFiveByFive.getUserHashedEmail(), this._timeout).then((function (response) {
                        _this._pending = _this._pending.filter(function (e) { return e !== '5x5'; });
                        if (!response) {
                            _this.checkIfReady();
                        }
                    }).bind(this));
                }
                else {
                    this.checkIfReady();
                }
                return [2 /*return*/];
            });
        });
    };
    IdentityAdapeterHandler.prototype.checkIfReady = function () {
        if (this._pending.length === 0 || this.ready === true) {
            (0, log_1.logInfo)('Identity adapter is ready');
            clearTimeout(this._timeout_handle);
            this._timeout_handle = null;
            this.ready = true;
            this.callback();
            this.callback = function () { };
        }
    };
    IdentityAdapeterHandler.prototype.checkUid2 = function (hashedEmail) {
        try {
            globalThis.pbjs.que.push((function () {
                var _a;
                // The name of the UID2 module
                var uid2ModuleName = 'uid2IdSystem';
                var uid2Name = 'uid2';
                var uid2Params = this.getExistingConfigParams(uid2Name);
                if (
                // Check hashed emial
                hashedEmail &&
                    // Check param values
                    !(uid2Params === null || uid2Params === void 0 ? void 0 : uid2Params.emailHash) &&
                    (uid2Params === null || uid2Params === void 0 ? void 0 : uid2Params.subscriptionId) &&
                    (uid2Params === null || uid2Params === void 0 ? void 0 : uid2Params.serverPublicKey) &&
                    // Uid2 value is not set
                    // !globalThis?.pbjs.getUserIds()[uid2Name] &&
                    // Uid2 module is installed
                    ((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _a === void 0 ? void 0 : _a.installedModules.indexOf(uid2ModuleName)) !== -1) {
                    (0, log_1.logInfo)('Updating UID2 hashedEmail');
                    // There is no token that can be used or refreshed. 
                    // The UID2 module must be configured with DII to generate a new token. 
                    // https://unifiedid.com/docs/getting-started/gs-normalization-encoding#email-address-hash-encoding
                    uid2Params.emailHash = (0, utils_1.hexToBase64)(hashedEmail); // base64 encode the hashed email
                    // update user id config
                    this.updateUserIds(uid2Name, uid2ModuleName, uid2Params);
                }
            }).bind(this));
        }
        catch (error) {
            (0, log_1.logError)('Error in UID2', error);
        }
        this.checkIfReady();
    };
    IdentityAdapeterHandler.prototype.checkId5 = function (hashedEmail) {
        try {
            globalThis.pbjs.que.push((function () {
                var _a;
                // The name of the Id5 module
                var id5ModuleName = 'id5IdSystem';
                var id5Name = 'id5Id';
                var id5Params = this.getExistingConfigParams(id5Name);
                if (
                // Check hashed emial
                hashedEmail &&
                    // Check param values
                    !(id5Params === null || id5Params === void 0 ? void 0 : id5Params.pd) &&
                    (id5Params === null || id5Params === void 0 ? void 0 : id5Params.partner) &&
                    // Uid2 value is not set
                    // !globalThis?.pbjs.getUserIds()[id5Name.toLowerCase()] &&
                    // Uid2 module is installed
                    ((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _a === void 0 ? void 0 : _a.installedModules.indexOf(id5ModuleName)) !== -1) {
                    var url = (0, utils_1.getURL)();
                    // https://wiki.id5.io/docs/passing-partner-data-to-id5
                    var pdKeys_1 = {
                        1: hashedEmail,
                        8: encodeURIComponent(url.href),
                        9: encodeURIComponent(url.hostname),
                        12: encodeURIComponent((0, utils_1.getUserAgent)()) // user agent
                    };
                    if (User_1.globalUser.ipv4) {
                        pdKeys_1[10] = encodeURIComponent(User_1.globalUser.ipv4);
                    }
                    if (User_1.globalUser.ipv6) {
                        pdKeys_1[11] = encodeURIComponent(User_1.globalUser.ipv6);
                    }
                    (0, log_1.logInfo)('Updating Id5. pdKeys:', pdKeys_1);
                    // convert the key/values into a querystring format
                    var pdRaw = Object.keys(pdKeys_1).map(function (key) { return key + '=' + pdKeys_1[key]; }).join('&');
                    // base64 encode the raw string; this is the final value you can pass into the pd field
                    id5Params.pd = (0, utils_1.hexToBase64)(pdRaw); // base64 encode the hashed email
                    // update user id config
                    this.updateUserIds(id5Name, id5ModuleName, id5Params);
                }
            }).bind(this));
        }
        catch (error) {
            (0, log_1.logError)('Error in ID5', error);
        }
    };
    IdentityAdapeterHandler.prototype.getExistingUserIds = function () {
        var _a, _b, _c, _d;
        try {
            var existingConfig = typeof ((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _a === void 0 ? void 0 : _a.getConfig) === 'function' && ((_b = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _b === void 0 ? void 0 : _b.getConfig('userSync')) || ((_d = (_c = _1.globalSettings === null || _1.globalSettings === void 0 ? void 0 : _1.globalSettings.prebid) === null || _c === void 0 ? void 0 : _c.config) === null || _d === void 0 ? void 0 : _d.userSync) || {};
            return existingConfig.userIds || [];
        }
        catch (error) {
            (0, log_1.logError)('Error getting existing user ids', error);
            return [];
        }
    };
    IdentityAdapeterHandler.prototype.getExistingConfigParams = function (configName) {
        try {
            // get the UID2 module configuration
            var uid2Config = this.getExistingUserIds().find(function (userId) {
                return userId.name === configName;
            });
            return (uid2Config === null || uid2Config === void 0 ? void 0 : uid2Config.params) || {};
        }
        catch (error) {
            (0, log_1.logError)('Error getting existing config params', error);
            return {};
        }
    };
    IdentityAdapeterHandler.prototype.updateUserIds = function (configName, moduleName, params) {
        var updatedUserIds = [];
        var existingUserIds = this.getExistingUserIds();
        var containsConfig = existingUserIds.some(function (userId) { return userId.name === configName; });
        if (containsConfig) {
            updatedUserIds = existingUserIds.map(function (userId) {
                return userId.name === configName ? { name: configName, params: params } : userId;
            });
        }
        else {
            updatedUserIds.push({ name: configName, params: params });
        }
        globalThis.pbjs.setConfig({
            userSync: {
                userIds: updatedUserIds
            }
        });
        // Refresh specific modules
        globalThis.pbjs.refreshUserIds({ submoduleNames: [moduleName] }, function () {
            (0, log_1.logInfo)("Refreshed ".concat(moduleName), globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs.getUserIds());
        });
    };
    IdentityAdapeterHandler.prototype.getUserIdsAsEids = function () {
        var _a, _b;
        return (ConsentHandler_1.allConsentHandler.isActivityAllowed(activities_1.ACTIVITY_ENRICH_EIDS, { gvlid: null })) ? ((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _a === void 0 ? void 0 : _a.getUserIdsAsEids) && ((_b = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _b === void 0 ? void 0 : _b.getUserIdsAsEids()) : [];
    };
    IdentityAdapeterHandler.prototype.getUserIds = function () {
        var _a, _b;
        return (ConsentHandler_1.allConsentHandler.isActivityAllowed(activities_1.ACTIVITY_ENRICH_EIDS, { gvlid: null })) ? ((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _a === void 0 ? void 0 : _a.getUserIdsAsEids) && ((_b = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _b === void 0 ? void 0 : _b.getUserIdsAsEids()) : {};
    };
    return IdentityAdapeterHandler;
}());
exports.globalIdentityAdapeterHandler = new IdentityAdapeterHandler();
